<template>
	<div>
		<div style="margin: 10px 0">
			<el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search" v-model="name"></el-input>
			<el-input style="width: 200px" placeholder="请输入标的编号" suffix-icon="el-icon-search" v-model="id"></el-input>
			<el-button class="ml-5" type="primary" @click="load">搜索</el-button>
			<el-button type="warning" @click="reset">重置</el-button>
		</div>

		<div style="margin: 10px 0">
			<el-button type="primary" @click="handleAdd">新增 <i class="el-icon-circle-plus-outline"></i></el-button>
			<!--      <el-popconfirm
          class="ml-5"
          confirm-button-text='确定'
          cancel-button-text='我再想想'
          icon="el-icon-info"
          icon-color="red"
          title="您确定批量删除这些数据吗？"
          @confirm="delBatch"
      >
        <el-button type="danger" slot="reference">批量删除 <i class="el-icon-remove-outline"></i></el-button>
      </el-popconfirm> -->
			<!--      <el-upload action="http://localhost:9090/biaodi/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">
        <el-button type="primary" class="ml-5">导入 <i class="el-icon-bottom"></i></el-button>
      </el-upload> -->
			<el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button>

<!-- 			<el-popconfirm title="是否删除所有数据?" @confirm='delall()'>
				<el-button type="danger" class="ml-5" slot="reference" style="z-index: 9999 !important;">清空所有标的数据（谨慎操作）
					<i class="el-icon-delete"></i></el-button>
			</el-popconfirm> -->
		</div>

		<el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"
			@selection-change="handleSelectionChange">
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column prop="id" label="标的编号" width="80" sortable></el-table-column>
			<el-table-column prop="name" label="项目名称"></el-table-column>
			<el-table-column prop="fuzeren" label="招标负责人"></el-table-column>
			<el-table-column prop="phone" label="联系方式"></el-table-column>
			<el-table-column prop="fabuTime" label="发布日期"></el-table-column>
			<el-table-column label="文件"><template slot-scope="scope">
					<el-button type="primary" @click="download(scope.row.biaodiFile)">下载</el-button>
				</template></el-table-column>
			<el-table-column prop="zbdanwei" label="中标单位"></el-table-column>
			<el-table-column label="操作" width="280" align="center">
				<template slot-scope="scope">
					<div v-if="scope.row.state!='已结束'">
						<el-button type="success" @click="handleEdit(scope.row)">编辑 <i class="el-icon-edit"></i>
						</el-button>
					</div>
					<div v-if="scope.row.state=='已结束'&&scope.row.zbdanwei==null">
						<el-button type="primary" @click="zhongbiao(scope.row)">中标设置 <i class="el-icon-edit"></i>
						</el-button>
					</div>
					<el-button type="success" plain @click="handelCopyLink(scope.row.id)">复制编号</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="padding: 10px 0">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
				:page-sizes="[15, 20, 25, 30]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</div>

		<el-dialog title="信息" :visible.sync="dialogFormVisible" width="60%">
			<el-form label-width="200px" size="small" autocomplete="on">
				<el-form-item label="标的时段/计划编号">
					<el-input v-model="form.name" id="keyword" autocomplete="on"></el-input>
				</el-form-item>
				<el-form-item label="标的物">
					<el-select v-model="form.pname" placeholder="请选择或输入标的物"  allow-create clearable filterable 
						>
						<el-option v-for="(item,index) in pnames" :key="index" :label="item":value="item" />
					</el-select>
				</el-form-item>
				<el-form-item label="起点">
					<el-select v-model="form.start" placeholder="请选择或输入起点"  allow-create clearable filterable 
						>
						<el-option label="独山子区" value="独山子区"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="终点">
					<el-select v-model="form.end" placeholder="请选择或输入终点"  allow-create clearable filterable 
						>
						<el-option v-for="(item,index) in ends" :key="index" :label="item":value="item" />
					</el-select>
				</el-form-item>
				<el-form-item label="规格">
					<el-select v-model="form.guige" placeholder="请选择或输入规格"  allow-create clearable filterable 
						>
						<el-option v-for="(item,index) in guiges" :key="index" :label="item":value="item" />
					</el-select>
				</el-form-item>
				<el-form-item label="计量单位">
					<el-select v-model="form.danwei">
						<el-option v-for="item in jloptions" :key="item.name" :label="item.name" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="数量">
					<el-input v-model="form.shuliang" id="keyword" autocomplete="on"></el-input>
				</el-form-item>
				<el-form-item label="拦标价">
					<el-input v-model="form.lanbiaojia" id="keyword" autocomplete="on"></el-input>
				</el-form-item>
				<el-form-item label="招标负责人">
					<el-select v-model="form.fuzeren" placeholder="请选择或输入招标负责人"  allow-create clearable filterable 
						>
						<el-option v-for="(item,index) in fuzerens" :key="index" :label="item":value="item" />
					</el-select>
				</el-form-item>
				<el-form-item label="联系方式">
					<el-select v-model="form.phone" placeholder="请选择或输入联系方式"  allow-create clearable filterable 
						>
						<el-option v-for="(item,index) in phones" :key="index" :label="item":value="item" />
					</el-select>
				</el-form-item>
				<el-form-item label="发布日期">
					<el-date-picker v-model="form.fabuTime" type="datetime" value-format="yyyy-MM-dd"
						placeholder="选择日期时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="标的文档">
					<el-upload action="http://www.xjtljt.cc:9091/file/upload" ref="file"
						:on-success="handleFileUploadSuccess">
						<el-button size="small" type="primary">点击上传</el-button>
					</el-upload>
				</el-form-item>
				<el-form-item label="标的公告">
					<vue-editor v-model="form.gonggao"></vue-editor>
				</el-form-item>
				<el-form-item label="状态">
					<el-select v-model="form.state" placeholder="">
						<el-option label="已发布" value="已发布" selected></el-option>
						<el-option label="已结束" value="已结束"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="供应商类型">
					<el-select v-model="form.gongyingshangtype" @change="jiebiaodanwei">
						<el-option key="固体投标单位" value="固体投标单位"></el-option>
						<el-option key="液体投标单位" value="液体投标单位"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="接标单位">
					<el-select v-model="value1"  filterable multiple collapse-tags default-first-option
						placeholder="请选择接标单位">
						<el-option v-for="item in jbdanwei" :key="item.id" :label="item.username"
							:value="item.username">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="save">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog title="中标单位设置" :visible.sync="dialogForm1Visible" width="23%">
			  <el-alert title="选择中标单位请按顺序选择,并输入对应选择顺序的中标数量" type="warning"></el-alert>
			<el-select v-model="value2" filterable multiple collapse-tags default-first-option
				placeholder="请选择中标单位" style="width:16.25rem;">
				<el-option v-for="item in options" :key="item.id" :label="item.username" :value="item.username">
				</el-option>
			</el-select>
			</hr>
			<el-input v-model="zbnum" autocomplete="off" placeholder="中标数量设置(用英文逗号隔开)" style="width:16.25rem;"></el-input>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogForm1Visible = false">取 消</el-button>
				<el-button type="primary" @click="tjzhongbiao">确 定</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		VueEditor
	} from "vue2-editor";
	export default {
		name: "Biaodi",
		components: {
			VueEditor
		},
		data() {
			return {
				tableData: [],
				programs: {},
				total: 0,
				pageNum: 1,
				pageSize: 10,
				name: "",
				options: [],
				jloptions: [],
				link: [],
				id: [],
				danweitype: "",
				form: {},
				zbnum: "",
				biaodi: {},
				dialogForm1Visible: false,
				dialogFormVisible: false,
				dialogForm2Visible: false,
				value2: [],
				value1: [],
				jbdanwei: [],
				jbdanweis: "",
				pnames:['MTBE',"双环戊二烯","普货固体"],
				ends:['库车','乌石化','米泉','八钢','大丰','鄯善县','库尔勒','阿克苏市','轮台县','托克逊县','成都','重庆','银川','奎屯','石河子','乌鲁木齐','库尔勒','乌苏市','乌苏凯赛','克拉玛依','昌吉','甘泉堡','米东区','吐鲁番','阿克苏','阿拉山口','鄯善','哈密','兰州','酒泉','咸阳','银川','石嘴山','西宁','成都','德阳','什邡','广汉','重庆','贵阳','六盘水','昆明','北京','天津','石家庄','邢台市宁晋','保定','张家口','秦皇岛','衡水','涿州','唐山','沧州','霸州','玉田','廊坊','赵县','任丘','雄县','太原','晋中','呼和浩特','巴彦淖尔','长春','吉林','沈阳','大连','哈尔滨','上海','桃浦','苏州','南京','常州','徐州','南通','如皋','泰州','无锡','昆山','太仓','海安','镇江','张家港','徐州','常熟','宜兴','杭州','绍兴','安吉县','长兴','扬州','温州','宁波','台州','浙江温岭','浙江临海','湖州','艮山门','海宁','桐乡','嘉兴','兰溪','丽水','余姚','慈溪','金华','合肥','马鞍山','滁州','巢湖','芜湖','黄山','歙县','淮北','泉州','泉州南安','厦门','福州','晋江','东孚','莆田','石狮','三明市','永安','南平','赣州','上高','九江','南昌','吉安','抚州','鹰潭','济南','青岛、胶州','平邑','枣庄','临沂','广饶','青州','烟台','即墨','东营','潍坊','德州','淄博','济宁','寿光','菏泽','安丘','威海','武汉','荆门','襄阳','宜昌','孝感','黄石','仙桃','郑州','濮阳','焦作','新密','平顶山','长沙','株洲','岳阳','常德','广州','韶关','深圳','佛山','江门','鹤山','茂名','东莞','惠州','大郎','汕头','南宁','桂林','贺州','柳州','海口'],
				guiges:['危化品3类','危化品2类','危化品8类','危化品9类','普通固体','吨包','500公斤包','25公斤包'],
				fuzerens:['覃次林','楚欣','郑新生'],
				phones:['18116853989','18116853358','18116853768'],
				users: [],
				multipleSelection: [],
				user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
			}
		},
		created() {
			this.load()
		},
		methods: {
			load() {
				this.request.get("/dict/danweis").then(res => {
					this.jloptions = res.data
				})

				this.request.get("/biaodi/page", {
					params: {
						pageNum: this.pageNum,
						pageSize: this.pageSize,
						name: this.name,
						id: this.id
					}
				}).then(res => {
					this.tableData = res.data.records
					this.total = res.data.total
				})
			},
			save() {
				
				this.jbdanweis = "";
				// 	this.form.fbdanwei=null;
				for (var i = 0; i < this.value1.length; i++) {
					if (i == this.value1.length - 1) {
						this.jbdanweis += this.value1[i];
					} else {
						this.jbdanweis += this.value1[i] + ",";
					}
				}
				this.form.fbdanwei = this.jbdanweis;

				this.request.post("/biaodi", this.form).then(res => {
					if (res.code === '200') {
						this.$message.success("保存成功")
						this.dialogFormVisible = false

						this.load()
					} else {
						this.$message.error("保存失败")
					}
				})
			},
			handleAdd() {
				this.dialogFormVisible = true
				// this.form = {}
				this.$nextTick(() => {
					if (this.$refs.img) {
						this.$refs.img.clearFiles();
					}
					if (this.$refs.file) {
						this.$refs.file.clearFiles();
					}
				})
			},
			handleEdit(row) {
				this.form = JSON.parse(JSON.stringify(row))
				var datas=this.form.fbdanwei.split(',');
				this.value1=datas;
				console.log(this.form)
				this.jiebiaodanwei(this.form.gongyingshangtype)
				this.dialogFormVisible = true
				this.$nextTick(() => {
					if (this.$refs.img) {
						this.$refs.img.clearFiles();
					}
					if (this.$refs.file) {
						this.$refs.file.clearFiles();
					}
				})
			},
			del(id) {
				this.request.delete("/biaodi/" + id).then(res => {
					if (res.code === '200') {
						this.$message.success("删除成功")
						this.load()
					} else {
						this.$message.error("删除失败")
					}
				})
			},
			handleSelectionChange(val) {
				console.log(val)
				this.multipleSelection = val
			},
			delBatch() {
				let ids = this.multipleSelection.map(v => v.id) // [{}, {}, {}] => [1,2,3]
				this.request.post("/biaodi/del/batch", ids).then(res => {
					if (res.code === '200') {
						this.$message.success("批量删除成功")
						this.load()
					} else {
						this.$message.error("批量删除失败")
					}
				})
			},
			reset() {
				this.name = ""
				this.load()
			},
			handleSizeChange(pageSize) {
				console.log(pageSize)
				this.pageSize = pageSize
				this.load()
			},
			handleCurrentChange(pageNum) {
				console.log(pageNum)
				this.pageNum = pageNum
				this.load()
			},
			handleFileUploadSuccess(res) {
				this.form.file = res
			},
			handleImgUploadSuccess(res) {
				this.form.img = res
			},
			download(url) {
				window.open(url)
			},
			exp() {
				window.open("https://www.xjtljt.cc:9091/biaodi/export")
			},
			handleExcelImportSuccess() {
				this.$message.success("导入成功")
				this.load()
			},
			changeState(obj) {
				this.entity = obj;
				this.save();
			},
			zhongbiao(row) {
				this.dialogForm1Visible = true
				this.biaodi = JSON.parse(JSON.stringify(row))
				this.request.get("/user/page", {
					params: {
						pageNum: 1,
						pageSize: 100,
						type:this.form.gongyingshangtype
					}
				}).then(res => {
					this.options = res.data.records;
				})
			},
			tjzhongbiao() {
				if (this.zbnum != "") {
					var zbnums = this.zbnum.split(',');
					if (zbnums.length == this.value2.length) {
						this.biaodi.zbnum = this.zbnum;
					} else {
						this.$message.error("中标数量和单位数量不符")
						return false;
					}
				}
				for (var i = 0; i < this.value2.length; i++) {
					if (i == this.value2.length - 1) {
						this.biaodi.zbdanwei += this.value2[i];
					} else {
						this.biaodi.zbdanwei += this.value2[i] + ",";
					}
				}
				if (this.biaodi.zbdanwei != undefined) {
					this.biaodi.zbdanwei = this.biaodi.zbdanwei.replace('undefined', '').replace('null', '');
				}
				this.request.post("/biaodi", this.biaodi).then(res => {
					console.log(res)
					if (res.code === '200') {
						this.$message.success("保存成功")
						this.dialogForm1Visible = false
						this.load()
					} else if (res.search('500') != -1) {
						this.$message.error("保存失败")
					}
				})
			},
			jiebiaodanwei(data) {
				this.jbdanwei = [];
				this.danweitype = data;
				this.request.get("/user/type?type=" + this.danweitype).then(res => {
					for (let i = 0; i < res.data.length; i++) {
						this.jbdanwei.push(res.data[i]);
					}
				})
			},
			handelCopyLink(val) {
				this.link = val;
				let that = this;
				this.$copyText(this.link).then(
					function(e) {
						that.$notify({
							title: "复制成功",
							type: "success",
						});
					},
					function(e) {
						that.$notify({
							title: "复制失败",
							type: "error",
						});
					}
				);
			},
			delall() {
				this.request.post("/biaodi/delAll").then(res => {
					if (res.code === '200') {
						this.$message.success("删除成功")
						this.load()
					} else {
						this.$message.error("删除失败")
					}
				})
			}
		}
	}
</script>


<style>
	.headerBg {
		background: #eee !important;
	}
</style>
